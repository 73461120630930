/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo} from "react";
import { WORKSPACES_PAGE_HEADING } from "@/config/general";
import {
  Cog,
  Database,
  Upload,
} from "lucide-react";
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import DataTable from "@/components/table/CustomDataTable";
import useSourcesStore, {
  usePagination,
} from "@/stores/sources.store";
import { Link} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { RelativeDateTime } from "@/components/misc/RelativeDateTime";
import useWorkspaceStore from "@/stores/workspaces.store";
import WorkspaceName from "@/components/misc/WorkspaceName";
import WorkspaceIcon from "@/components/misc/WorkspaceIcon";
import { WorkspaceSheet } from "@/components/dialog/WorspaceSheet";

const Workspaces: React.FC = () => {
  const {
    setCurrentPage,
    setPageSize,
  } = useSourcesStore();

  const { currentPage, pageSize, totalPages, totalRows } = usePagination();

  const [workspaceOverlayIsOpen, setWorkspaceOverlayIsOpen] = React.useState(false);
  const [workspaceIdToEdit, setWorkspaceIdToEdit] = React.useState<string | undefined>();

  const [workspaces, loadingWorkspace, totalWorkspaces] = useWorkspaceStore((state) => [
    state.workspaces,
    state.loading,
    state.totalWorkspaces,
  ]);

  const cols = useMemo(
    () => [
      {
        id: "workspaceId",
        name: "",
        width: 20,
        sortable: true,
        cell: (row: any) => {
          const storedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
          const isActive = storedWorkspaceId === row;
          return (
            <WorkspaceIcon 
              id={row} 
              isActive={isActive}
            />
          );
        },
      },
      {
        id: "workspaceName",
        name: "Name",
        width: 250,
        sortable: true,
        cell: (row: any) => {
          const storedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
          const isActive = storedWorkspaceId === row.workspaceId;
          return (
            <WorkspaceName 
              fullText={row} 
              isActive={isActive}
            />
          );
        },
      },
      {
        id: "settings.currency",
        name: "Default Currency",
        width: 50,
        sortable: true,
      },
      {
        id: "settings.priceSource",
        name: "Price Source",
        width: 100,
        sortable: true,
      },
      {
        id: "updated",
        name: "Last Updated",
        width: 100,
        cell: (value: any) => {
          const parseCustomTimestamp = (timestamp: number): Date => {
            const timestampStr = timestamp.toString();
            const year = parseInt(timestampStr.slice(0, 4), 10);
            const month = parseInt(timestampStr.slice(4, 6), 10) - 1; // JS months are 0-indexed
            const day = parseInt(timestampStr.slice(6, 8), 10);
            const hours = parseInt(timestampStr.slice(8, 10), 10);
            const minutes = parseInt(timestampStr.slice(10, 12), 10);
            const seconds = parseInt(timestampStr.slice(12, 14), 10);

            return new Date(year, month, day, hours, minutes, seconds);
          };

          const parsedDate = parseCustomTimestamp(value);

          return <RelativeDateTime value={parsedDate.getTime()} />; // Pass the timestamp (in ms)
        },
        sortable: true,
      },
    ],
    []
  );

  const actionButtons = useMemo(
    () => [
      {
        onClick: () => {
          setWorkspaceIdToEdit(undefined);
          setWorkspaceOverlayIsOpen(true);
        },
        text: "New Workspace",
        icon: Upload,
      },
    ],
    []
  );

  const rowActions = useMemo(
    () => [
      {
        text: "Manage",
        icon: Cog,
        onClick: (rowData: any) => {
          setWorkspaceIdToEdit(rowData?.workspaceId);
          setWorkspaceOverlayIsOpen(true);
        },
      },
    ],
    []
  );

  const changePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  const changePageSize = useCallback(
    (size: number) => {
      setPageSize(size);
    },
    [setPageSize]
  );

  return (
    <>
      <WorkspaceSheet
      setIsOpen={setWorkspaceOverlayIsOpen}
    isOpen={workspaceOverlayIsOpen}
    workspaceId={workspaceIdToEdit}
      />
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-1 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <Database
            size={18}
            strokeWidth={1.75}
            className="dark:text-gray-100"
          />
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Workspaces</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
      </section>

      <DashboardMainCard
        header={WORKSPACES_PAGE_HEADING}
        description={`${totalWorkspaces} Active Workspaces`}
      >
        <DataTable
          columns={cols}
          data={workspaces}
          loading={loadingWorkspace}
          globalFilter={{
            placeholder: "Filter Workspaces...",
            accessorKey: "workspaceName",
          }}
          actionButtons={actionButtons}
          pagination={{
            currentPage,
            pageSize,
            totalPages,
            totalRows,
            onPageChange: changePage,
            onPageSizeChange: changePageSize,
          }}
          rowActions={rowActions}
        />
      </DashboardMainCard>
    </>
  );
};

export default Workspaces;
